.image_color img {
    width: 100%;
    height: 550px;
    object-fit: cover;
    border-radius: 15px;
}
 .heading .Austr_text {
    padding-bottom: 2px;
}
.curren_hop a {
    text-decoration: none;
    border-bottom: none;
}
.curren_hop a:hover {
    text-decoration: none;
    border-bottom: none;
}