.ganerate_p{
    display: flex;
    flex-direction: column;
}
.ganerate_p span{
    margin-top: 10px;
    color: #a6804d;
    font-weight: 700;
}
.file_request_spinner {
    border-color: red;
}

.request_file_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.request_file_btns_group{
    display: flex;
}

.re_sub_btn{
    display: flex;
    align-items: center;
}
.upr_case{
    text-transform: uppercase;
}

.accordion-button:not(.collapsed) {
    color: #a6804d !important;
    background: #eaebed !important;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}

.hr_spacing{
    padding: 0px 20px 0px 20px;
}