/** @format */

.main_wrapper .enviornment_consideration li button {
  border: none !important;
  color: #000;
  width: 100%;
  padding: 20px;
  background: #f5f7ff;
  border-radius: 8px 8px 0px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #a6804d;
}
.enviornment_consideration {
  border-bottom: none !important;
}
.color_div .enviornment_consideration li .nav-link.active {
  border: none !important;
  color: #fff !important;
  border-bottom: 1px solid #2f2216 !important;
  margin: 0 auto;
  background: linear-gradient(
    91.53deg,
    #614332 13.86%,
    #8f673f 112.43%
  ) !important;
  box-shadow: 0px 4px 10px rgb(25 97 156 / 20%);
  border-radius: 0px 0px 0px 0px;
  padding: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
}
