/** @format */

.tableContainer {
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 50px;
  padding: 20px;
}

.primaryBtn {
  width: 254px;
  height: 44px;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.23px;
  letter-spacing: 0.2em;
  text-align: left;
  color: #fff;
  border-radius: 8px;
  background: linear-gradient(
    91.53deg,
    #614332 13.86%,
    #8f673f 112.43%
  ) !important;
  text-align: center;
}

.tableContainer .tableHeadRow {
  height: 58px;
}

.tableContainer .tableHeadCell {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.52px;
  letter-spacing: 0.05em;
  text-align: left;
  background: #2f2216;
  color: #fff;
  padding: 16px;
}

.tableContainer .tableHeadCell:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableContainer .tableHeadCell:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tableContainer .tableBodyCell div input {
  width: 9%;
}
.tableContainer .tableBodyCell {
  /* width: 100%; */
  /* height: 80px;   */
  background-color: #fff;
  border-radius: 2px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  /* height: 60px; */
}

.tableContainer .disabledCellBox {
  background-color: #f7f8fd;
  padding: 0px 16px;
  font-size: 14px;
  height: 60px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.tableContainer .cellBox {
  border: 1px solid #eeeeee;
  padding: 20px 16px;
  font-size: 14px;
}

.tableContainer .activeCellBox {
  background-color: #EEE2D6;
  border: 0px;
  padding: 0px 16px;
  font-size: 14px;
  height: 60px;
  display: flex;
  justify-content: left;
  align-items: center;
  word-break: break-word;
}

.tableContainer .tableBodyRow:last-child .tableBodyCell:first-child {
  border-bottom-left-radius: 2px;
}

.tableContainer .tableBodyRow:last-child .tableBodyCell:last-child {
  border-bottom-right-radius: 2px;
}

.tableContainer .tableBody {
  border-collapse: separate;
  border-spacing: 10px; /* Adjust the value to change the gap between cells */
}

.tableContainer th,
.tableContainer td {
  padding: 10px; /* Add padding to cells for better readability */
}

.activityInp i {
  display: none; /* Hide icon by default */
}

.activityInp:hover i {
  display: inline; /* Display icon when input is hovered */
}

.activeFactor input {
  height: 60px;
  background-color: #fff;
  border-radius: 2px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  border: 2px solid;
}

.inactiveFactor input {
  height: 60px;
  border-radius: 2px;
  border: 0px !important;
  background-color: #EEE2D6!important;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}

.btnContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 74px;
  border-top: 1px solid #e1e2e7;
  margin-top: 50px;
}

.table {
  width: 100%;
}

.group_cell {
  color: #2f2216;
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  margin-left: 12px;
}
