.container {
  padding: 40px;
  height: 87vh;
}

.tabContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: #b6dfd9; */
}

.container .tabBox {
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.container .visitedTab {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #8C7671;
}

.container .activeTab {
  width: 214px;
  height: 50px;
  border-radius: 200px;
  background-color: #fff;
  border: 1px solid A6804D;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #8C7671;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .unVisitedTab {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #a6804d;
}

.activeTab {
  position: relative;
}
/* .activeTab::before {
  content: "";
  top: 50%;
  left: 214px;
  width: 50px;
  height: 2px;
  background-color: #8C7671;
  position: absolute;
} */
/* .activeTab::after {
  content: "";
  top: 50%;
  left: 264px;
  width: 15px;
  height: 15px;
  background-color: #8C7671;
  position: absolute;
  transform: translateY(-48%);
  border-radius: 50%;
} */
