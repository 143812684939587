/** @format */

.text_c {
  width: 27%;
  padding: 13px 11px;
}

.color_div_key {
  background: linear-gradient(360deg, white, #ffffff9c);
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 0px 0px 10px 10px;
  padding: 31px 28px;
}

.main__container {
  position: relative;
}

.clamp-lines {
  max-height: calc(
    1.2em * 4
  ); /* Adjust the line height and number of lines as needed */
  line-height: 1.2em; /* Adjust the line height as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.companyName-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.card .card-block .text-muted {
  color: #fff !important;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.hideData {
  display: none;
}

.esgDownloadReporting {
  background: #eee2d6 !important;
  width: 240px;
}

.pol1 {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #a6804d;
}

.Download_esg_report {
  width: 100%;
  height: 43px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  bottom: 0;
  left: 0;
  right: 0;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 1px dashed #d1d8f3;
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 30px auto 0px;
}

.drag-text {
  padding: 20px;
}

.drag-text h3 {
  font-size: 16px;
  text-align: center;
  padding: 10px;
}

.drag-text i {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #a6804d;
}

.text-outside p {
  text-align: center;
  padding: 10px;
}

.image-upload-wrap a {
  text-decoration: none;
  color: #636464;
}

.image-upload-wrap a:hover {
  color: #636464;
}

.div_scrolls {
  height: calc(100vh - 400px);
  overflow-y: scroll;
}

@media (max-width: 1440px) {
  .current_score {
    max-width: 150px !important;
  }
}

@media (max-width: 1200px) {
  .current_score {
    max-width: 0px !important;
    margin: 34px auto 0px 150px !important;
  }
}

.div_scroll li a {
  width: 100%;
  float: left;
  padding: 5px;
  color: #2f2216;
}

.div_scroll::-webkit-scrollbar:vertical {
  width: 3px;
}

.div_scroll::-webkit-scrollbar:horizontal {
  height: 7px;
}

.div_scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #301e18;
  background-color: #614332;
}

.color_div._q {
  padding: 0px 0;
  /* height: calc(100vh - 600px); */
  /* overflow-y: scroll; */
  border-radius: 10px;
  margin-bottom: 20px;
}

.color_div._q::-webkit-scrollbar:vertical {
  width: 3px;
}

.color_div._q::-webkit-scrollbar:horizontal {
  height: 5px;
}

.color_div._q::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #614332;
  background-color: #301e18;
}

.main_text {
  display: flex;
  flex-direction: column;
}

.hd_process {
  display: flex;
  margin: 48px 0;
  justify-content: center;
}

.hd_process img {
  position: absolute;
  top: 70px;
  bottom: 0;
  max-width: 460px;
}

.pie-wrapper:nth-child(3n + 1) {
  clear: both;
}

.pie-wrapper {
  width: 100%;
  max-width: 16em;
  height: 16em;
  float: left;
  margin: 44px 15px;
  position: relative;
}

.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}

.pie-wrapper.style-2 .label {
  background: #fff;
  color: #232e76;
  display: flex;
  flex-direction: column;
  border: 11px solid #41c7ed;
}

.pie-wrapper .label {
  border-radius: 50%;
  bottom: 0;
  cursor: default;
  display: block;
  font-size: 3em;
  left: 0;
  line-height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  font-weight: 600;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie-wrapper.style-2 .label .smaller {
  color: #bdc3c7;
}

.pie-wrapper .label .smaller {
  color: #bdc3c7;
  font-size: 0.45em;
  padding-bottom: 20px;
  vertical-align: super;
}

.pie-wrapper.progress-95 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}

.pie-wrapper.progress-95 .pie .left-side {
  transform: rotate(342deg);
}

.pie-wrapper.progress-95 .pie .half-circle {
  border-color: #e74c3c;
}

.pie-wrapper .pie .half-circle {
  height: 100%;
  width: 100%;
  border: 0.1em solid #3498db;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  left: 0;
  position: absolute;
  top: 0;
}

.pie-wrapper .shadow {
  height: 100%;
  width: 100%;
  border: 1.1em solid #41c7ed;
  border-radius: 50%;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

span.exc {
  font-size: 0.5em;
  color: #3db1db;
}

.font {
  position: absolute;
  top: 12px;
  right: 25px;
  transform: rotate(226deg);
}

.font i {
  font-size: 54px;
  color: #41c7ed;
}

.enviornment_consideration li {
  width: 33.33%;
  text-align: center;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .hd_process .pie-wrapper {
    max-width: 12em;
    height: 12em;
    top: 19px;
    margin: 9px;
  }
}

.current_score {
  width: 100%;
  max-width: 413px;
  min-height: 333px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 40px auto 0 170px;
}

.current_score .current_h4 {
  margin-top: 30px;
  font-size: 30px;
  font-weight: 500;
}

.current_score .score {
  position: relative;
}

.current_score .score h4 {
  color: A6804D;
  font-weight: 700;
  position: absolute;
  z-index: 999;
  bottom: -50px;
  text-align: center;
  word-break: break-all;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.current_score .score .half-arc {
  position: relative;
  width: 258px;
  height: 126px;
  border-top-left-radius: 120px;
  border-top-right-radius: 120px;
  border-bottom: 0;
  background: #fff;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 7px;
}

.current_score .score .half-arc:before {
  content: '';
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 204%;
  border-radius: 50%;
  background-image: conic-gradient(
    #0a5294,
    #50acaa calc(var(--percentage, 0) / 2),
    #eee 0
  );
  transition: transform 0.5s ease-in-out;
  z-index: 1;
  transform: rotate(270deg);
}

.current_score .score .half-arc:after {
  content: '';
  position: absolute;
  display: block;
  background: #fff;
  z-index: 2;
  width: calc(100% - 32px);
  height: calc(200% - 32px);
  border-radius: 50%;
  top: 16px;
  left: 16px;
}

.current_score .score .half-arc span {
  color: #0169d1;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 91px;
  font-size: 40px;
  font-weight: 700;
}

.current_score .score_dashboard {
  width: 228px;
  height: 224px;
  background: #fff;
  box-shadow: 0px 17px 25px #b9b9b9;
  border-radius: 50%;
  position: absolute;
  top: 29px;
  left: 15px;
  z-index: 2;
}

.current_score .sore_ligh_background {
  width: 256px;
  height: 264px;
  background: #e7f2f2;
  border-radius: 50%;
  position: absolute;
  top: 6px;
}

.current_score .sore_ligh_background::before {
  position: absolute;
  content: '';
  width: 203px;
  height: 130px;
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  border-top: 48px solid #fff;
  left: 34px;
}

.current_score .score_img {
  width: 100%;
  display: flex;
  position: relative;
  top: 128px;
  justify-content: center;
}

.current_score .edit_button {
  width: 100%;
  max-width: 361px;
  display: flex;
  margin-top: 160px;
  z-index: 3;
  margin-bottom: 20px;
}

.current_score .edit_button button {
  width: 100%;
  background-color: #075099;
}

.esg_score_title {
  background: #f5f7ff;
  border-radius: 8px 8px 0px 0px;
  font-weight: 600;
  font-size: 21px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #2f2216;
  padding: 30px 15px;
}

.module_button_download {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.btn.btn.esgDownloadReporting {
  border: 1px solid #7d563a;
  filter: drop-shadow(0px 4px 10px #8f673fc0);
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  text-transform: capitalize;
  color: #7d563a;
  padding: 10px;
}

#slider {
  width: 300px;
}

#slidervalue {
  position: absolute;
  top: 50px;
  left: 50px;
  font-size: 50px;
  font-weight: 100;
  transition: color 1s linear;
  border: 1px solid;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#svgcontainer {
  position: relative;
  display: inline-block;
  width: 300px;
}

#svg {
  position: relative;
}

#progressbg {
  z-index: 0;
  position: absolute;
  top: 0px;
  box-shadow: 5px 5px 5px 5px #333;
  stroke: #d9d9d926;
}

#progress {
  stroke-dashoffset: 125px;
  stroke: #7df;
  transition: stroke 1s linear;

  z-index: 1;
  position: absolute;
  top: 0px;
}

.cssprogresscontainer {
  padding: 0 50px 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.above_average {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: #a6804d;
  line-height: 45px;
}

span.radio_check_1 {
  color: #fff;
  border-radius: 50%;
  border: 7px solid #896764;
  padding: 0px 6px;
  margin: 5px;
}

span.radio_check_2 {
  color: #fff;
  border-radius: 50%;
  border: 7px solid #f4b450;
  padding: 0px 6px;
  margin: 5px;
}

span.radio_check_3 {
  color: #fff;
  border-radius: 50%;
  border: 7px solid #f2e856;
  padding: 0px 6px;
  margin: 5px;
}
.platform {
  height: 32px;
}

/* .t_head {
  text-align: center;
} */
