.download_button {
  padding: 8px;
  border-radius: 50%;
  border: 1.5px solid #08b49d;
  background: transparent;
  cursor: pointer;
  margin: 0 auto;
}

.fa.fa-download {
  color: #7d563a;
}

.report-table thead,
tbody {
  text-align: center;
}
