

.text-center{
    /* z-index: 100;
    position: absolute; */
    text-align: center!important; 
}

.loader__container{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
    backdrop-filter: blur(10px);
}
