.working_img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.working_img img {
  margin: 30px 0;
}
.working_text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.working_text h4 {
  font-size: 42px;
  font-weight: 900;
  color: #8F673F;
  margin: 20px 0;
}

.working_text p {
  font-size: 20px;
}
.text-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_r img {
  width: 100%;
}
.text-successFul h4 {
  font-size: 42px;
  font-weight: 900;
  color: #8F673F;
  margin: 0 0 30px 0px;
}
.text-successFul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* align-items: center; */
}
.progresses {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  justify-content: center;
}

.steps i {
  font-size: 10px;
}

.steps {
  display: flex;
  background-color: #63d19e;
  color: #fff;
  font-size: 14px;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.steps {
  display: flex;
  background-color: #8F673F;
  color: #fff;
  font-size: 14px;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
span.line1 {
  width: 100%;
  max-width: 274px;
  height: 6px;
  background: #8F673F;
}
span.line {
  width: 100%;
  max-width: 306px;
  height: 6px;
  background: #8F673F;
}
.text-head span {
  font-weight: 700;
}
