.completion-section {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
}

.completion-text {
  margin: 60px 0;
}

.inner_completion-section {
  width: 400px;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.inner_completion-section .note {
  margin-top: 30px;
  text-align: center;
}
