/** @format */

.parent-enviornment .enviornment {
  display: block;
}
.page_width {
  /* width: auto !important; */
  padding: 20px !important;
  text-decoration: none;
}

.page_width_mini {
  width: auto !important;
  padding: 10px !important;
}

@media (min-width: 768px) and (max-width: 1401px) {
  .page_width {
    /* padding: 15px !important; */
  }
}

.gement2 {
  width: 100%;
  max-width: 200px;
}
.manag .form-floating textarea#floatingTextarea {
  padding-top: 34px;
}
.manag .form-floating textarea#floatingTextarea {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.manag .form-floating textarea#floatingTextarea::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.page_save_green {
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 30px;
  color: A6804D;
  background: #fff;
}
.page_save_anchor {
  background: linear-gradient(94.47deg, #2f2216 1.14%, #7D563A 114.41%);
  box-shadow: 0px 8px 52px #B4AA90;
  border-radius: 8px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 18px !important;
  align-items: flex-end !important;
  justify-content: center;
  text-align: center !important;
  letter-spacing: 0.2em !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
  margin-right: 30px !important;
  width: auto !important;
  padding: 20px !important;
}
.framwork_2 {
  height: auto !important;
  padding: 50px !important;
}
.framwork_2 .E_capital {
  padding: 0 0px 30px 0px;
}
.re-submit {
  /* color: #2F2216; */
  color: #A6804D!important;
  background: #fff;
  border: none;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 16px !important;
  letter-spacing: 2px;
}
.back_quninti_2 {
  display: initial !important;
}
div#sidebar-wrapper {
  position: fixed;
  overflow: scroll;
}
div#sidebar-wrapper {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

div#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

.buttons button {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  background: #2f2216;
  padding: 20px;
}
.buttons button:hover {
  color: #fff !important;
}
/* .statement_icon span.statement_check {
    width: 25px;
    height: 25px;
} */

@media (min-width: 320px) and (max-width: 1440px) {
  div#sidebar-wrapper::-webkit-scrollbar {
    width: 4px;
    height: 1px;
    display: block;
  }
}
