/** @format */

.main_card {
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  /* height: 212px; */
  box-shadow: 0px 0px 15px rgba(25, 97, 156, 0.2);
}

.tableHead {
  background-color: #2f2216;
  color: #ffffff;
}

.tableHead .rounded_left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tableHead .rounded_right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.table_head_col {
  padding: 15px;
}

/* .ui.input input {
    height: 60px;
    border-radius: 2px;
    border: 0px;
    background-color: EEE2D6;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
} */

.loader_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}
