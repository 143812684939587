/** @format */

body {
  background-color: #eee2d6 !important;
}
.color_div_step {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgb(222 227 245 / 60%);
  border-radius: 8px;
  padding: 50px 51px;
}
.div-color {
  margin-top: 70px;
}
.ste-steps {
  position: relative;
}
.parent-enviornment {
  position: absolute;
  top: -130px;
}
.ste-steps .parent-enviornment .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #A6804D!important;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 25px;
  border: 1px solid #A6804D!important;
}
.parent-enviornment li.nav-item {
  /* padding: 0px 100px 0 0; */
  padding: 0;
}
.parent-enviornment {
  display: flex;
  flex-grow: 2;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-bottom: none !important;
  flex-wrap: wrap;
  flex: 0 0 50;
}
.parent-enviornment button {
  color: #a6804d;
}
.parent-enviornment button:hover {
  color: A6804D;
}
.form_x.mb-3 {
  display: flex;
}
.form_x label.form-label {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  /* justify-content: end; */
  align-items: center;
  margin-right: 20px;
  color: #a6804d;
  font-weight: 600;
}
.form_x select.form-select {
  border: 1px solid #A99882;
  height: 50px;
}
.dropdown input#dropdownMenuLink {
  background: #ffff;
  border: 1px solid #A99882;
  text-align: left;
}
.dropdown .dropdown-menu.border-0.shadow.show {
  padding: 10px;
}
.dropdown .shadow ul {
  display: block;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  height: 140px;
}
.dropdown .shadow ul::-webkit-scrollbar {
  -webkit-appearance: none;
}

.dropdown .shadow ul::-webkit-scrollbar:vertical {
  width: 7px;
}

.dropdown .shadow ul::-webkit-scrollbar:horizontal {
  height: 11px;
}

.dropdown .shadow ul::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #edeff6; /* should match background, can't be transparent */
  /* background-color: rgba(0, 0, 0, .5);*/
  background-color: #edeff6;
}
.dropdown .shadow ul li {
  text-decoration: none;
  display: block;
}
.check-form {
  display: flex !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  /* max-width: 300px; */
  justify-content: space-between;
}
.check-form img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 10px;
}

.Global_text .form-check input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.Global_text .form-check input:checked {
  background-color: #C6AF7E;
  border: none;
  cursor: pointer;
}
