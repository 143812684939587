/** @format */

.drag-text h3 {
  font-size: 12px;
  text-align: center;
  padding: 10px 0 0;
  color: #a6804d;
  line-height: 19px;
  margin-bottom: 0;
}

.question_two.text-center {
  width: 100%;
  margin: 0 auto;
}

.issue_it {
  width: 100% !important;
  max-width: 400px !important;
  /* margin: 0 auto !important; */
}

.text_suppliers {
  display: flex;
}

.add_text {
  position: absolute;
  right: 83px;
}

.supploi {
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #284d8df0;
  padding: 5px 10px;
  border-radius: 5px;
}

.supploi:hover {
  color: #ffffff;
  background-color: A6804D;
  padding: 5px 10px;
  border-radius: 5px;
}

.supploi1 {
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #949494;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #949494;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.supploi1:hover {
  color: #949494;
  background-color: #f9f7f7fc;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #949494;
}

.image-upload-wrap1 {
  margin-top: 20px;
  border: 1px dashed #d1d8f3;
  position: relative;
  width: 100%;
  max-width: 280px;
  /* margin: 30px auto 0px; */
}

.iconcolor {
  color: #59595a;
}

hr.heling {
  margin: 35px 0;
  height: 1px;
  left: 350px;
  top: 802px;
  background: #A99882;
  border-radius: 0px;
}

.table_f .table tr th {
  padding: 20px 13px;
  font-size: 16px;
  color: #a6804d;
}

.admin-risk-table .table tr th {
  padding-left: 10px;
}

.admin-risk-table .table tr th {
  padding-left: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #2f2216;
  border-style: revert;
}

.admin-risk-table .center.red {
  justify-content: flex-start;
}

.admin-risk-table .center.green {
  justify-content: flex-start;
}

.admin-risk-table .center.bold {
  justify-content: flex-start;
}

.table_f table tbody tr td {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #a6804d !important;
  border-style: revert;
}

.manage-detail .global_link .page_save.page_width {
  min-width: 100px;
}

.manage-detail td {
  word-break: break-word;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #f5f7ff;
  color: var(--bs-table-striped-color);
}

.upload-document {
  position: relative;
  cursor: pointer;
}

.upload-document .upload_files {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  opacity: 0;
  top: 0px;
  right: 65px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.supply-addd {
  text-decoration: none;
}

.supply-add {
  color: #2f2216;
  opacity: 0.7;
}

.info-box {
  position: relative;
}

.info_box-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: -24px;
  right: -22px;
}

.info_box-icon img {
  width: 100%;
  height: 100%;
  cursor: help;
}

.info_box-content {
  /* display: none; */
  position: absolute;
  top: -120px;
  width: 400px;
  right: 0px;
  background-color: #f5f7ff;
  color: #2f2216;
  padding: 10px 14px;
  border-radius: 10px;
  z-index: 4;
  box-shadow: 0px 3px 15px 0px rgba(130, 128, 153, 0.8);
  opacity: 0;
  transition: opacity 0.25s;
}

.info-box:hover .info_box-content {
  opacity: 0.99;
}

.modal_header {
  padding: 20px;
}

.modal_header-logo {
  width: 196px;
  margin-top: 40px;
  margin-left: 45px;
}

.modal_header-logo > img {
  width: 100%;
}

.modal-report-content {
  padding: 50px 45px 40px 45px;
}

.modal-report-content .title {
  color: #000000 !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  text-align: center;
  margin-bottom: 36px;
}

.modal-report-content .description {
  font-size: 12px !important;
  line-height: 23px !important;
  text-align: center;
}

.modal-report-content .score-form,
.my-modal .score-form {
  margin-top: 50px;
}

.modal-report-content .score-form .form-group {
  margin-bottom: 30px !important;
}

.modal-report-content .score-form .name_nf {
  background: #f8fbff;
  border: 1px solid #2F2216;
  box-sizing: border-box;
  border-radius: 6px;
}

.modal-report-content .score-form .form-buttons {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.modal-report-content .score-form .note {
  font-weight: 700;
  font-size: 12px !important;
  line-height: 18px;
}

.cancel_page {
  width: 200px;
  height: 58px;
  left: 350px;
  top: 835px;
  background: #bcbcbc;
  box-shadow: 0px 8px 52px rgb(163 163 163 / 36%);
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 30px;
}

.cancel_page:hover {
  background: #cbcbcb;
}

.my-modal .modal-content {
  background-color: #e8edfe;
}

.my-modal .modal-data-content {
  margin: 40px auto;
  max-width: 970px;
  width: 100%;
}

.my-modal .companies-data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;

  margin-bottom: 50px;
  position: relative;
}

.companies-data .companies-data-card {
  display: flex;
  width: 310px;
  box-sizing: border-box;
  padding: 18px 20px;
  gap: 16px;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 14px;
}

.company-info p.caption {
  margin-top: 0 !important;
  margin-bottom: 8px !important;
  padding-top: 0 !important;
  font-size: 16px !important;
  font-weight: 600;
  color: #000000 !important;
  line-height: 22px !important;
}

.company-info p.text {
  font-size: 14px !important;
  line-height: 27px;
  color: #454545;
  padding: 0 !important;
  margin: 0 !important;
}

.prev-button,
.next-button {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button.prev-button {
  left: -60px;
}

button.next-button {
  right: -60px;
}

.loader-block {
  position: absolute;
  top: 48vh;
  left: 48%;
  z-index: 999999;
}

.custom-select {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid #A99882;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  line-height: 1.1;
  background-color: #f8fbff;
}

.custom-select:first-child {
  color: #6d757d;
}

.down_rep {
  min-width: 270px;
}
